@import '../../globalStyle.scss';

.warp {
   
   // background-color: #eee;
    // border: red 1px solid;
}
.pages{
   
  //  background-color: #eee;
}
.topC{

    border: rgb(134, 160, 187) 1px solid;
  
}

.centC{
   
     border: rgb(134, 160, 187) 1px solid;
  
}
.selectC{
    color: rgb(134, 160, 187);
    font-size: 20px;
    font-weight: 600;
}

.top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: vwCalc(1665);
    height: vwCalc(200);
    // border: red 1px solid;

}

.cube1 {
    width: vwCalc(300);
    height: vwCalc(150);
    // border: red 1px solid;
    border-radius: 15px;
    background-image: linear-gradient(136deg, rgba(248, 178, 79, 0.3) 0%, rgba(200, 147, 71, 0.9) 100%);
    ;


}

.cube2 {
    width: vwCalc(300);
    height: vwCalc(150);
    // border: red 1px solid;
    border-radius: 15px;
    background-image: linear-gradient(136deg, rgba(231, 219, 136, 0.3) 0%, rgba(202, 139, 4, 0.7) 100%);
    ;


}

.content {
    display: block;
    font-family: Roboto-Medium;
    margin-top: vhCalc(20);
    font-size: vhCalc(40);
    font-weight: 700;
    color: whitesmoke;

    text-align: center;
}

.text {
    display: block;
    font-family: Roboto-Medium;
    font-size: vhCalc(35);
    font-weight: 500;
    color: whitesmoke;
    text-align: center;

}

.middle {
   
}

.bottom {
   // width: vwCalc(1000);
   padding-top: 50px;
    height: vwCalc(450);
    // border: red 1px solid;
}

// button {
//     background-color: rgba(230, 174, 96, 0.9) !important;
//     border-color: rgba(223, 180, 121, 0.9) !important;

//     :hover {
//         background-color: rgba(240, 217, 186, 0.9) !important;
//     }
// }