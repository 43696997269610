@import '../../../globalStyle';

.top {
    // width: vwClac(300);
    // height: vhCalc(125);
    // border: red 1px solid;
    margin-bottom: vhCalc(20);
}

.middle {
    // width: vwClac(300);
    height: vhCalc(675);
    // border: red 1px solid;

}